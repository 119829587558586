import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { useStores } from 'RootStore';
import ConsignedRepresentative from 'consignments/consignmentsComponents/consignedRepresentative';
import ConsignmentPanel from 'consignments/consignmentsComponents/consignmentPanel';
import { isOwnerApprovalNeeded } from 'consignments/domain';
import { ConsignmentStatus, Consignment as ConsignmentType } from 'consignments/types';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import ArtworkSummary from 'theme/atoms/artworkSummary';
import Loader from 'theme/atoms/loader';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import UserCard from 'theme/atoms/userCard';
import { useTitle } from 'utils/hooks';
import styles from './Consignment.module.scss';
import ConsignmentTopbar from './components/ConsignmentTopbar';
import Actions from './components/actions/Actions';
import ConsignmentDetails from './components/consignmentDetails';

const getTitle = (consignment: ConsignmentType): string => {
  const { expireAt, isConsignee, status } = consignment;

  const isAccepted = status === 'Accepted';
  const isRejected = status === 'RejectedByOwner' || status === 'RejectedByConsignee';
  const isExpired = status === 'Expired' || (expireAt && dayjs().isAfter(expireAt));

  if (isAccepted) return 'Accepted consignment';
  if (isRejected) return 'Rejected consignment';
  if (isExpired) return 'Expired consignment';

  return isConsignee ? 'Accept consignment agreement' : 'Approve consigning';
};

export const Consignment = (): JSX.Element => {
  useTitle('Consignment Details');

  const { consignmentId } = useParams();

  const { consignmentStore } = useStores();
  const { fetchConsignment, consignmentItem, consignment, loaded, isPanelOpen, setIsPanelOpen } = consignmentStore;

  const debouncedIsPanelOpen = useDebounceIsPanelOpen(isPanelOpen);

  useEffect(() => {
    consignmentId && fetchConsignment(consignmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consignmentId]);

  if (!loaded) {
    return <Loader minHeight={400} />;
  }

  if (!consignmentItem || !consignment) {
    return <NoDataToDisplay copy="No consignment to display." height={100} />;
  }

  const { artwork } = consignmentItem;
  const { isConsignee, consignorContact } = consignment;
  const hasAccessToArtwork = !(
    isConsignee &&
    [ConsignmentStatus.RejectedByConsignee, ConsignmentStatus.RejectedByOwner].includes(consignment.status)
  );

  const artworkSummaryFrom =
    isConsignee && consignorContact ? (
      <UserCard id={consignorContact.contactConnectionId || consignorContact.organizationName} isSmall>
        <UserCard.Name name={consignorContact.organizationName} />
      </UserCard>
    ) : null;

  const consignedRepresentative = !isConsignee && (
    <div className={styles.consignee}>
      <SectionHeader title="Consigned representative" />
      <ConsignedRepresentative
        consigneeContact={consignment.consigneeContact}
        onClick={() => isOwnerApprovalNeeded(consignment) && debouncedIsPanelOpen && setIsPanelOpen(true)}
        withIcon={isOwnerApprovalNeeded(consignment)}
      />
    </div>
  );

  return (
    <>
      <ConsignmentTopbar artworkId={artwork.id} hasAccessToArtwork={hasAccessToArtwork} />
      <ArtworkSummary title={getTitle(consignment)} from={artworkSummaryFrom} artwork={artwork} />

      <div className={styles.consignmentData}>
        <div className={styles.consignmentDetails}>
          {consignedRepresentative}
          {isOwnerApprovalNeeded(consignment) ? <Actions /> : <ConsignmentDetails />}
        </div>
      </div>

      <ConsignmentPanel isOpen={isPanelOpen} onClose={() => setIsPanelOpen(false)} />
    </>
  );
};

export default observer(Consignment);
