import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { ContactConnection } from 'network/types';
import { getAddress, isContactConnection } from 'network/utils';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import Loader from 'theme/atoms/loader';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import { useTitle } from 'utils/hooks';
import ContactActionSection from '../components/contactActionSection';
import ContactAvatar from '../components/contactAvatar';
import ContactInfo from '../components/contactInfo';
import styles from './ContactInvtation.module.scss';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Network', route: 'Network' }, { label: 'Contact Invitation' }];

const ContactInvitationContent = observer((): JSX.Element | null => {
  const { contactInvitationStore } = useStores();
  const {
    contactInvitation: invitation,
    loadingInvitation,
    createContactConnection,
    loadingConnection,
    contactInvitation,
  } = contactInvitationStore;

  const [invitationStatus, setInvitationStatus] = useState<string | null>(null);

  const connectHandler = (): void => {
    if (contactInvitation)
      createContactConnection(contactInvitation.id).then((info: string | ContactConnection | void) => {
        if (typeof info === 'string') {
          setInvitationStatus(info);
        } else if (isContactConnection(info)) {
          setInvitationStatus(info.status);
        }
      });
  };

  if (loadingInvitation) {
    return <Loader minHeight={200} />;
  }

  if (!invitation) {
    return <NoDataToDisplay copy="No invitation to display." height={100} />;
  }

  return (
    <div className={styles.root}>
      <ContactAvatar id={invitation.id} />
      <div className={styles.wrapper}>
        <ContactInfo
          name={invitation.organizationName}
          title={invitation.organizationTitle || ''}
          address={getAddress(invitation.organizationAddress)}
          withIcon
        />
        <ContactActionSection
          onClick={() => !invitationStatus && connectHandler()}
          loading={loadingConnection}
          status={invitationStatus}
        />
      </div>
    </div>
  );
});

const ContactInvitation = (): JSX.Element => {
  useTitle('Invitation Details');

  const { invitationId } = useParams();
  const { contactInvitationStore } = useStores();
  const { getContactInvitation } = contactInvitationStore;

  useEffect(() => {
    if (invitationId) getContactInvitation(invitationId);
  }, [invitationId, getContactInvitation]);

  return (
    <>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <ContactInvitationContent />
    </>
  );
};

export default observer(ContactInvitation);
