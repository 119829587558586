import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import Big from 'big.js';
import { InvoiceState } from 'domain/types/Invoices.types';
import { ReactComponent as CommentIcon } from 'theme/assets/svg/comment.svg';
import { ReactComponent as PaidIcon } from 'theme/assets/svg/paidTransaction.svg';
import BillingCard from 'theme/atoms/billingCard';
import InvoiceStateCheckIcon from 'theme/atoms/invoiceStateCheckIcon';
import { Table } from 'theme/atoms/table/Table';
import { Invoice } from 'transactions/requests.types';
import { formatPrice } from 'utils/helpers';
import styles from './InvoiceTable.module.scss';

export const InvoiceTable = ({ isPayer }: { isPayer: boolean }): JSX.Element => {
  const { invoicesStore } = useStores();
  const { items } = invoicesStore;
  const invoices = items as Invoice[];

  const navigate = useNavigate();

  const handleInvoiceDetailsClick = (id: string): void => {
    navigate(routes.Invoice.path(id));
  };

  return (
    <div className={styles.root}>
      <Table className={styles.table}>
        <>
          {invoices.map((el) => {
            const contact = isPayer ? el.issuerContactDto : el.payerContactDto;
            const isPaid = el.state === InvoiceState.Paid;
            const isRejected = el.state === InvoiceState.Rejected;

            return (
              <tr
                key={el.id}
                className={clsx(styles.rowTable, { [styles.rejectedInvoiceRow]: isRejected })}
                onClick={() => handleInvoiceDetailsClick(el.id)}
              >
                <td className={styles.invoiceStateTd}>
                  <InvoiceStateCheckIcon
                    className={styles.stateIcon}
                    state={el.state}
                    withStateText
                    createdDate={el.createdAt}
                    withoutTooltip
                  />
                </td>
                <td className={styles.transactionSiteTd}>
                  <BillingCard.TransactionSiteAvatar
                    className={styles.personWrapper}
                    label={isPayer ? 'To: ' : 'From: '}
                    organizationId={el.id}
                    organizationName={contact?.organizationName || 'Undisclosed'}
                  />
                </td>
                <td className={styles.bankNoteTd}>
                  {isPayer && el.bankNote && (
                    <div className={styles.bankNoteContainer}>
                      <CommentIcon />
                      <span>{el.bankNote}</span>
                    </div>
                  )}
                </td>
                <td className={styles.priceTd}>
                  <div className={styles.priceWrapper}>
                    {formatPrice(Big(el.amount), el.currency, 2)}
                    {isPaid && <PaidIcon />}
                  </div>
                </td>
              </tr>
            );
          })}
        </>
      </Table>
    </div>
  );
};

export default observer(InvoiceTable);
