import { ReactNode, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import SortAndFilterPanelArtworks from 'artworks/artworksComponents/sortAndFilter';
import SortAndFilterPanelConsignments from 'consignments/consignments/sortAndFilter';
import { notFilterParams } from 'domain/PaginationBasicStore/PaginationBasicStore';
import { CategoryId, DirType, FilterItemType, SortItemType } from 'domain/SortAndFilterBasicStore/types';
import { Button, Input } from 'theme/atoms';
import { PaginationTopbar, ROWS_PER_PAGE_SMALLEST_OPTION } from 'theme/atoms/paginationTopbar/PaginationTopbar';
import SortAndFilterPanelTransactions from 'transactions/transactions/sortAndFilter';
import { useDebounce } from 'utils/hooks/useDebounce';
import styles from './SearchTable.module.scss';
import { NumberIcon } from './components/NumberIcon';
import { SearchbarIconButton } from './components/SearchbarIconButton';
import {
  FilterStore,
  RegularStore,
  SORT_BY_STRING,
  SearchTableView,
  StoreWithGroupCounts,
  StoreWithCategory,
  StoreWithFilters,
  viewsWithCategory,
  viewsWithGroupCounts,
} from './constants';

const DEBOUNCE_DELAY = 300;
const PARAMS_NOT_TO_BE_CHANGED_WHEN_OTHER_PARAMS_CHANGE = ['phrase', 'rowsPerPage'];

interface SearchTableProps {
  children: ReactNode;
  dontShowSearchbox?: boolean;
  dontShowSortAndFilterPanel?: boolean;
  filterStore: FilterStore;
  store: RegularStore;
  view: SearchTableView;
}

const SearchTable = <T extends CategoryId>({
  children,
  dontShowSearchbox,
  dontShowSortAndFilterPanel,
  filterStore,
  store,
  view,
}: SearchTableProps): JSX.Element => {
  const {
    activePage,
    allItemsCount,
    allPagesCount,
    fetchItems,
    items,
    resetActivePage,
    resetAll,
    resetItems,
    rowsPerPage,
    searchQuery,
    selectedItemsIds,
    selectOrUnselectAllItems,
    setActivePage,
    setRowsPerPage,
    setSearchQuery,
    shouldSortAndFilterButtonBeDisabled,
  } = store;
  const { fetchGroupCounts } = filterStore as StoreWithGroupCounts;
  const { setCategoryId } = filterStore as StoreWithCategory;
  const { filterByAsParams } = filterStore as StoreWithFilters;
  const {
    overwriteSelectedItemsWithConfirmedItems,
    removeSelectedSortingItem,
    resetConfirmedFilteringAndSortingItemsBeforeSettingThemFromUrl,
    selectedFilteringItems,
    takeDefaultItemIfMissingUserChoice,
    updateConfirmedFilteringItems,
    updateConfirmedSortingItems,
    updateSelectedSortingItems,
    validSelectedSortingItems,
  } = filterStore;
  const [sortAndFilterPanelIsOpen, setSortAndFilterPanelIsOpen] = useState(false);
  const { categoryId } = useParams();
  const [isInputChanged, setIsInputChanged] = useState(false);
  const [isInitialLoadingFinished, setIsInitialLoadingFinished] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);
  const debouncedLocalSearchQuery = useDebounce(localSearchQuery, DEBOUNCE_DELAY);

  const handleSubmitSortsAndFilters = (): void => {
    const paramsFromUrl = Object.keys(Object.fromEntries(new URLSearchParams(location.search)));
    paramsFromUrl.forEach(
      (el) => !PARAMS_NOT_TO_BE_CHANGED_WHEN_OTHER_PARAMS_CHANGE.includes(el) && searchParams.delete(el)
    );
    Object.entries(filterByAsParams(selectedFilteringItems, true)).forEach(([key, value]) => {
      if (!notFilterParams.includes(key) && value && value.length !== 0) {
        setSearchParams((params) => {
          params.set(key, value);
          return params;
        });
      }
    });
    validSelectedSortingItems.forEach((el) => {
      setSearchParams((params) => {
        params.set(SORT_BY_STRING + el.value, `${el.dir}`);
        return params;
      });
    });
  };

  useEffect(() => {
    return () => {
      resetAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSortAndFilterFromUrl = (): void => {
    resetConfirmedFilteringAndSortingItemsBeforeSettingThemFromUrl();
    const params = Object.keys(Object.fromEntries(new URLSearchParams(location.search)));
    params
      .filter((el) => !notFilterParams.includes(el) && !el.startsWith(SORT_BY_STRING))
      .forEach((el) => {
        const valueAsArray = searchParams.get(el)?.split(',');
        if (!valueAsArray) return;
        valueAsArray.forEach((v) => {
          const obj = { key: el, value: v } as FilterItemType;
          updateConfirmedFilteringItems(obj);
        });
      });
    params
      .filter((el) => el.startsWith(SORT_BY_STRING))
      .forEach((el) => {
        updateConfirmedSortingItems(el, searchParams.get(el) as DirType);
      });
    takeDefaultItemIfMissingUserChoice();
    overwriteSelectedItemsWithConfirmedItems();
  };

  useEffect(() => {
    if (!isInitialLoadingFinished) {
      const activePageFromUrl = searchParams.get('activePage');
      setActivePage(activePageFromUrl ? parseInt(activePageFromUrl, 10) : 1);
      const rowsPerPageFromUrl = searchParams.get('rowsPerPage');
      setRowsPerPage(rowsPerPageFromUrl ? parseInt(rowsPerPageFromUrl, 10) : ROWS_PER_PAGE_SMALLEST_OPTION);
      setSearchQuery(searchParams.get('phrase') || '');
      if (categoryId && viewsWithCategory.includes(view)) {
        setCategoryId(categoryId as T);
      }
      setSortAndFilterFromUrl();
      fetchItems(true);
      setIsInitialLoadingFinished(true);
    } else {
      setSortAndFilterFromUrl();
      fetchItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (viewsWithCategory.includes(view)) {
      if (categoryId) {
        setCategoryId(categoryId as T);
      }
      if (categoryId && viewsWithGroupCounts.includes(view)) {
        fetchGroupCounts();
      }

      if (isInitialLoadingFinished) {
        if (searchParams.get('activePage') === '1') {
          fetchItems(true);
        } else {
          setSearchParams((params) => {
            params.set('activePage', '1');
            return params;
          });
          resetActivePage();
        }
        resetItems();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  useEffect(() => {
    if (!isInitialLoadingFinished || !isInputChanged) return;
    setSearchParams((params) => {
      debouncedLocalSearchQuery ? params.set('phrase', debouncedLocalSearchQuery) : params.delete('phrase');
      return params;
    });
    setSearchQuery(searchParams.get('phrase') || '');
    handleChangeActivePage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLocalSearchQuery]);

  useEffect(() => {
    !localSearchQuery && searchQuery && setLocalSearchQuery(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (!sortAndFilterPanelIsOpen) {
      overwriteSelectedItemsWithConfirmedItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortAndFilterPanelIsOpen]);

  const handleRemoveSearch = (): void => {
    setLocalSearchQuery('');
  };

  const handleChangeRowsPerPage = (value: number): void => {
    setSearchParams((params) => {
      params.set('rowsPerPage', value.toString());
      return params;
    });
    const rowsPerPageFromUrl = searchParams.get('rowsPerPage');
    setRowsPerPage(rowsPerPageFromUrl ? parseInt(rowsPerPageFromUrl, 10) : ROWS_PER_PAGE_SMALLEST_OPTION);
    handleChangeActivePage(1);
  };

  const handleChangeActivePage = (value: number): void => {
    setSearchParams((params) => {
      params.set('activePage', value.toString());
      return params;
    });
    const activePageFromUrl = searchParams.get('activePage');
    setActivePage(activePageFromUrl ? parseInt(activePageFromUrl, 10) : 1);
  };

  const sortAndFilterPanelProps = {
    isOpen: sortAndFilterPanelIsOpen,
    onClose: () => setSortAndFilterPanelIsOpen(false),
    onSubmit: handleSubmitSortsAndFilters,
    updateSelectedSortingItems: (index: number, updatedItem: SortItemType) =>
      updateSelectedSortingItems(index, updatedItem),
    removeSelectedSortingItem: (updatedItem: SortItemType) => removeSelectedSortingItem(updatedItem),
  };

  const showSortAndFilterPanel = (): JSX.Element => {
    switch (view) {
      case SearchTableView.Artworks:
        return <SortAndFilterPanelArtworks {...sortAndFilterPanelProps} />;
      case SearchTableView.Consignments:
        return <SortAndFilterPanelConsignments {...sortAndFilterPanelProps} />;
      case SearchTableView.Transactions:
        return <SortAndFilterPanelTransactions {...sortAndFilterPanelProps} categoryId={categoryId} />;
      //TODO uncomment when filtering/sorting logic in BE is ready
      // case SearchTableView.BankTransactions:
      //   return <SortAndFilterPanelBankTransactions {...sortAndFilterPanelProps} />;
      // case SearchTableView.BankInvoices:
      //   return <SortAndFilterPanelBankInvoices {...sortAndFilterPanelProps} />;
      default:
        return <></>;
    }
  };

  return (
    <div className={styles.root}>
      {!dontShowSearchbox && (
        <div className={styles.searchboxWrapper}>
          <Input
            className={styles.searchbox}
            name="searchbox"
            type="text"
            placeholder="Search..."
            endIcon={<SearchbarIconButton isSearchQuery={!!searchQuery} onRemove={handleRemoveSearch} />}
            value={localSearchQuery || ''}
            onChange={(e) => {
              setIsInputChanged(true);
              setLocalSearchQuery(e.target.value);
            }}
          />
          {!dontShowSortAndFilterPanel && (
            <div>
              <Button
                disabled={shouldSortAndFilterButtonBeDisabled !== null && shouldSortAndFilterButtonBeDisabled}
                buttonType="secondary"
                text="Sort and filter"
                onClick={() => setSortAndFilterPanelIsOpen(true)}
                iconEnd={<NumberIcon filterStore={filterStore} />}
              />
              {showSortAndFilterPanel()}
            </div>
          )}
        </div>
      )}
      {children}
      <PaginationTopbar
        allRows={allItemsCount}
        activePage={activePage}
        onChangeActivePage={(value) => handleChangeActivePage(value)}
        pages={allPagesCount}
        isAllSelected={selectedItemsIds.length > 0 && items.length === selectedItemsIds.length}
        onChangeSelectAll={(value: boolean) => selectOrUnselectAllItems(value)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(value: number) => handleChangeRowsPerPage(value)}
      />
    </div>
  );
};

export default observer(SearchTable);
