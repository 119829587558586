import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { CONSIGNMENT_CATEGORIES_WITH_STATUSES } from 'consignments/constants';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import Loader from 'theme/atoms/loader';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import SearchTable from 'theme/cells/searchTable/SearchTable';
import { SearchTableView } from 'theme/cells/searchTable/constants';
import { useTitle } from 'utils/hooks';
import styles from './Consignments.module.scss';
import ListConsignmentsTable from './listConsignments/ListConsignmentsTable';
import { ConsignmentCategoryId } from './types';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Consignments' }];

export const Consignments = (): JSX.Element => {
  useTitle('Consignments');

  const navigate = useNavigate();
  const { categoryId } = useParams<{ categoryId: ConsignmentCategoryId }>();
  const { consignmentsStore, consignmentsSortAndFilterStore } = useStores();
  const { items, loading } = consignmentsStore;
  const [searchParams] = useSearchParams();

  const consignmentsTable = loading ? (
    <Loader minHeight={150} />
  ) : items?.length > 0 ? (
    <ListConsignmentsTable />
  ) : (
    <NoDataToDisplay copy="No consignments to display." height={100} />
  );

  const selectCategory = (id: string): void => {
    navigate({
      pathname: routes.Consignments.path(id),
      search: searchParams.toString(),
    });
  };

  return (
    <div className={styles.root}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <SearchTable
        view={SearchTableView.Consignments}
        store={consignmentsStore}
        filterStore={consignmentsSortAndFilterStore}
      >
        <ul className={styles.categoriesWrapper}>
          {CONSIGNMENT_CATEGORIES_WITH_STATUSES.map(({ id, label }) => (
            <li key={id} onClick={() => selectCategory(id)} className={clsx({ [styles.active]: id === categoryId })}>
              {label}
            </li>
          ))}
        </ul>
      </SearchTable>
      {consignmentsTable}
    </div>
  );
};

export default observer(Consignments);
