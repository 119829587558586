import { useLocation } from 'react-router-dom';
import { TitleBlack } from 'theme/atoms';
import Accordion from 'theme/atoms/Accordion';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useTitle } from 'utils/hooks';
import styles from './SettingsSecurity.module.scss';
import AuthMethod from './components/AuthMethod';
import ChangePassword from './components/ChangePassword';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Settings' }, { label: 'Security', route: 'SettingsSecurity' }];

const SettingsSecurity = (): React.JSX.Element => {
  useTitle('Account Security');

  const location = useLocation();
  const isAuthMethodsOpen = location.state?.isAuthenticationMethodsOpen;

  return (
    <div className={styles.root}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>

      <TitleBlack text="Security" dontLimitWidth />

      <Accordion itemsOpenByDefault={isAuthMethodsOpen && ['authenticationMethod']}>
        <ChangePassword />
        <AuthMethod />
      </Accordion>
    </div>
  );
};

export default SettingsSecurity;
