import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { getAddress } from 'network/utils';
import { ReactComponent as EditIcon } from 'theme/assets/svg/edit.svg';
import { ReactComponent as ShareIcon } from 'theme/assets/svg/share.svg';
import ActionButtons, { ActionButton } from 'theme/atoms/ActionButtons';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import UserCard from 'theme/atoms/userCard/UserCard';
import { useTitle } from 'utils/hooks';
import styles from './SettingsOrganizationProfile.module.scss';
import OrganizationProfileEditPanel from './components/organizationProfileEditPanel';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Settings' }, { label: 'Organization Profile' }];

const SettingsOrganizationProfile = (): React.JSX.Element => {
  useTitle('Organization Profile');

  const { organizationStore } = useStores();
  const { selectedOrganization, updateOrganization } = organizationStore;
  const navigate = useNavigate();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const debouncedIsPanelOpen = useDebounceIsPanelOpen(isPanelOpen);

  const actionButtons: ActionButton[] = [
    {
      text: 'Share',
      icon: <ShareIcon />,
      onClick: () => navigate(routes.OrganizationProfileShare.path()),
    },
    {
      text: 'Edit',
      icon: <EditIcon />,
      onClick: () => debouncedIsPanelOpen && setIsPanelOpen(!isPanelOpen),
    },
  ];

  return (
    <>
      <div className={styles.root}>
        <Topbar>
          <Breadcrumbs breadcrumbs={BREADCRUMBS} />
          <ActionButtons buttons={actionButtons} />
        </Topbar>
        <div className={styles.profile}>
          <UserCard id={selectedOrganization?.id || ''} isBig type={selectedOrganization?.type}>
            <UserCard.Profile
              name={selectedOrganization?.name || ''}
              title={selectedOrganization?.title || ''}
              address={selectedOrganization?.address && getAddress(selectedOrganization.address)}
              hasLocationIcon
              description={selectedOrganization?.profileDescription}
              url={selectedOrganization?.url}
              numberOfLinesInName={2}
            />
          </UserCard>
        </div>
      </div>

      <OrganizationProfileEditPanel
        onClose={() => setIsPanelOpen(false)}
        isOpen={isPanelOpen}
        onSave={updateOrganization}
      />
    </>
  );
};

export default observer(SettingsOrganizationProfile);
