import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { FormValidation } from 'domain/types';
import { Button, TitleBlack } from 'theme/atoms';
import FormError from 'theme/atoms/formError';
import Loader from 'theme/atoms/loader';
import { useTitle } from 'utils/hooks';
import { useFormValidation } from 'utils/hooks';
import styles from '../AddArtwork.module.scss';
import { AddArtworkSteps, AddArtworkTopbar, CancelAddArtwork, OwnershipProof, PickOwner } from '../components';
import { ARTWORK_STEPS } from '../constants';
import NoArtworkFound from './components/NoArtworkFound';

const CURRENT_STEP = ARTWORK_STEPS[2];

const FORM_VALIDATIONS: FormValidation = {
  ownerRequired: '',
  ownershipProofRequired: '',
  totalShares: '',
  shareRequired: '',
};
const NEXT_STEP = ARTWORK_STEPS[3];

const AddArtworkOwnership = (): JSX.Element => {
  useTitle('Register Artwork');

  const { addArtworkStore } = useStores();
  const {
    fetching,
    artworkToEdit,
    ownersToDisplay,
    artworkForm,
    totalShares,
    ownersWithEmptyParticipationPercent,
    fetchArtworkAndSetItToEdition,
    saveDraft,
    updateArtworkForm,
    setArtworkToEdit,
  } = addArtworkStore;

  const navigate = useNavigate();
  const { artworkId } = useParams();
  const { cleanValidationErrors, formValidationMessages, handleValidation, validation, areThereAnyValidationErrors } =
    useFormValidation(FORM_VALIDATIONS);

  useEffect(() => {
    setArtworkToEdit(true);
    if (artworkId && artworkId !== 'new') fetchArtworkAndSetItToEdition(artworkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artworkId]);

  if (!artworkToEdit) return <NoArtworkFound />;

  const handleSaveAndNavigate = (): void => {
    const isNotValid =
      !ownersToDisplay ||
      ownersToDisplay.length === 0 ||
      !totalShares.eq(100) ||
      ownersWithEmptyParticipationPercent.length > 0 ||
      artworkForm.ownershipProofUrls.length <= 0;
    handleValidation(`ownerRequired`, ' (required)', !ownersToDisplay || ownersToDisplay.length === 0);
    handleValidation(
      `totalShares`,
      'The total of shares must be equal 100',
      ownersToDisplay.length > 0 && !totalShares.eq(100)
    );
    handleValidation('shareRequired', formValidationMessages.REQUIRED, ownersWithEmptyParticipationPercent.length > 0);
    handleValidation('ownershipProofRequired', ' (required)', artworkForm.ownershipProofUrls.length <= 0);

    if (isNotValid) return;

    if (artworkId) {
      updateArtworkForm('draftCreationStep', NEXT_STEP.id);
      saveDraft(artworkId).then((id) => {
        if (id) navigate(routes.AddArtworkSummary.path(id));
      });
    }
  };

  const buttons = (
    <div className={styles.buttonsWrapper}>
      <div className={styles.formError}>
        <FormError showError={areThereAnyValidationErrors} />
      </div>
      <Button text="Continue to summary" maxWidth onClick={handleSaveAndNavigate} />
      <Button
        text="Back"
        maxWidth
        onClick={() => artworkId && navigate(routes.AddArtworkDetails.path(artworkId))}
        buttonType="secondary"
      />
      <CancelAddArtwork
        artworkId={artworkId || 'new'}
        currentStep={ownersToDisplay.length === 0 ? CURRENT_STEP.id : undefined}
      />
    </div>
  );

  return (
    <>
      {fetching ? (
        <Loader minHeight={150} />
      ) : (
        <>
          <AddArtworkTopbar step={CURRENT_STEP.label} />
          <TitleBlack text="Register artwork" />
          <div className={styles.root}>
            <div className={styles.mainColumn}>
              <PickOwner cleanValidationErrors={cleanValidationErrors} validation={validation} />
              <OwnershipProof
                onAdd={() => cleanValidationErrors(['ownershipProofRequired'])}
                error={validation.ownershipProofRequired}
              />
              {buttons}
              <div>Info about cost of the service will be here.</div>
            </div>
            <AddArtworkSteps step={CURRENT_STEP.label} />
          </div>
        </>
      )}
    </>
  );
};

export default observer(AddArtworkOwnership);
