import { observer } from 'mobx-react-lite';
import { TitleBlack } from 'theme/atoms';
import { Subtitle } from 'theme/atoms';
import Accordion from 'theme/atoms/Accordion';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useTitle } from 'utils/hooks';
import styles from './SettingsKYC.module.scss';
import BankAccount from './components/bankAccount';
import LegalEntity from './components/legalEntity';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Settings' }, { label: 'Identity verification (KYC)' }];

const SettingsKYC = (): JSX.Element => {
  useTitle('Identity Verification (KYC)');

  return (
    <div className={styles.root}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>

      <TitleBlack text="Identity verification (KYC)" dontLimitWidth />
      <Subtitle
        className={styles.kycInfo}
        text="To ensure the security of your account and comply with regulatory requirements, we need to verify your identity."
      />

      <Accordion>
        <LegalEntity />
        <BankAccount />
      </Accordion>
    </div>
  );
};
export default observer(SettingsKYC);
