import { FormEvent, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { DEFAULT_ARTWORK_DRAFT_CATEGORY } from 'artworks/artworkRegistration/constants';
import { FormValidation } from 'domain/types';
import { AssetWithKey } from 'domain/types/Asset.types';
import { Button, TitleBlack } from 'theme/atoms';
import Breadcrumbs from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import ApprovalFromPeople from 'theme/atoms/approvalFromPeople';
import FormError from 'theme/atoms/formError';
import Loader from 'theme/atoms/loader';
import { getKeyFromUrl } from 'theme/cells/assetUpload/utils';
import { useFormValidation, useTitle } from 'utils/hooks';
import styles from '../AddArtwork.module.scss';
import {
  ArtworkDetailsForm,
  Certificate,
  OwnershipProof,
  PickAuthor,
  PickOwner,
  UndisclosedDocuments,
} from '../components';
import AssetsUpload from '../components/assetsUpload';
import { REQUIRED_FORM_FIELDS } from '../constants';
import NoArtworkFound from './components/NoArtworkFound';

const FORM_VALIDATIONS: FormValidation = REQUIRED_FORM_FIELDS.reduce(
  (prevFields, currentField) => {
    return { ...prevFields, [`${currentField}Required`]: '' };
  },
  {
    shareRequired: '',
    totalShares: '',
    ownerRequired: '',
    ownershipProofRequired: '',
    authorRequired: '',
    imageUrlsRequired: '',
  }
);

const EditArtwork = (): JSX.Element => {
  useTitle('Edit Artwork');

  const { addArtworkStore } = useStores();
  const {
    fetching,
    fetchArtworkAndSetItToEdition,
    updateArtworkForm,
    saveDraft,
    totalShares,
    ownersWithEmptyParticipationPercent,
    artworkForm,
    ownersToDisplay,
    authorToDisplay,
    artworkToEdit,
    setArtworkToEdit,
  } = addArtworkStore;
  const { artworkId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setArtworkToEdit(true);
    artworkId && artworkId !== 'new' && fetchArtworkAndSetItToEdition(artworkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artworkId]);

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation, areThereAnyValidationErrors } =
    useFormValidation(FORM_VALIDATIONS);

  const ownersNotBeingSelfLength = (ownersToDisplay && ownersToDisplay?.filter((owner) => !owner.self).length) || 0;
  const authorNotBeingSelfOrOwnerLength =
    authorToDisplay?.self || ownersToDisplay?.find((owner) => owner.contactConnectionId === authorToDisplay?.id)
      ? 0
      : 1;
  const peopleToApprove = ownersNotBeingSelfLength + authorNotBeingSelfOrOwnerLength;

  const updateForm = (name: string, value: string | number): void => {
    updateArtworkForm(name, value);
    cleanValidationErrors([`${name}Required`]);
  };

  const validateForm = (): boolean => {
    let isValid = true;
    handleValidation(`authorRequired`, ' (required)', !authorToDisplay);
    handleValidation(`imageUrlsRequired`, ' (required)', artworkForm.imageUrls.length === 0);
    handleValidation('ownershipProofRequired', ' (required)', artworkForm.ownershipProofUrls.length < 1);
    handleValidation(`ownerRequired`, '(required)', !ownersToDisplay || ownersToDisplay.length === 0);
    handleValidation(
      `totalShares`,
      'The total of shares must be equal 100',
      ownersToDisplay.length > 0 && !totalShares.eq(100)
    );
    handleValidation('shareRequired', formValidationMessages.REQUIRED, ownersWithEmptyParticipationPercent.length > 0);
    isValid =
      artworkForm.imageUrls.length > 0 &&
      authorToDisplay !== null &&
      artworkForm.ownershipProofUrls.length > 0 &&
      ownersToDisplay.length > 0 &&
      totalShares.eq(100) &&
      ownersWithEmptyParticipationPercent.length === 0;

    REQUIRED_FORM_FIELDS.forEach((field) => {
      const isEmpty = artworkForm[field] === null || artworkForm[field].length === 0;
      handleValidation(`${field}Required`, formValidationMessages.REQUIRED, isEmpty);

      isValid = isValid && !isEmpty;
    });

    return isValid;
  };

  const handleSave = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm() && artworkId) {
      saveDraft(artworkId).then((id) => {
        if (id) navigate(routes.ArtworkDraft.path(artworkId));
      });
    } else return;
  };

  const assetsFromParent = useMemo(() => {
    return artworkForm.imageUrls?.map((file) => {
      return {
        ...file,
        key: getKeyFromUrl(file.presignedUrl),
      };
    });
  }, [artworkForm.imageUrls]);

  if (!artworkToEdit) return <NoArtworkFound />;

  const imagesAsList = (
    <AssetsUpload
      assetsFromParent={assetsFromParent}
      // showAsList
      onUpdateAssets={(images: AssetWithKey[]) => updateArtworkForm('imagesUrls', images)}
      instruction="Upload artwork visual representation"
      title="Artwork cover images"
      assetsType="image"
      onClickAdd={() => cleanValidationErrors(['imageUrlsRequired'])}
      validationError={<span className={styles.error}>{validation.imageUrlsRequired}</span>}
    />
  );

  const buttons = (
    <div className={styles.buttonsWrapper}>
      <div className={styles.formError}>
        <FormError showError={areThereAnyValidationErrors} />
      </div>
      <Button text="Save changes" maxWidth onClick={(e) => handleSave(e)} />
      <Button
        text="Cancel"
        maxWidth
        onClick={() =>
          navigate(
            artworkId
              ? routes.ArtworkDraft.path(artworkId)
              : routes.ArtworkRegistration.path(DEFAULT_ARTWORK_DRAFT_CATEGORY)
          )
        }
        buttonType="secondary"
      />
    </div>
  );

  return (
    <>
      {fetching ? (
        <Loader minHeight={150} />
      ) : (
        <>
          <Topbar>
            <Breadcrumbs breadcrumbs={[{ label: 'Edit draft' }]} />
          </Topbar>
          <TitleBlack dontLimitWidth text="Edit draft" />
          <div className={styles.mainColumn}>
            <PickAuthor
              error={validation.authorRequired}
              cleanError={() => cleanValidationErrors(['authorRequired'])}
            />
            <Certificate
              onAdd={() => cleanValidationErrors(['certificateRequired'])}
              error={validation.certificateRequired}
            />
            <ArtworkDetailsForm onChange={updateForm} validation={validation} />
            {imagesAsList}
            <UndisclosedDocuments />
            <PickOwner cleanValidationErrors={cleanValidationErrors} validation={validation} />
            <OwnershipProof
              onAdd={() => cleanValidationErrors(['ownershipProofRequired'])}
              error={validation.ownershipProofRequired}
            />
            <ApprovalFromPeople peopleNumber={peopleToApprove} variant={'artworkRegistration'} />
            {buttons}
          </div>
        </>
      )}
    </>
  );
};

export default observer(EditArtwork);
