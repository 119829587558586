import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import BankPerspectiveLayout from 'bankPerspective/components/bankPerspectiveLayout';
import { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import { useTitle } from 'utils/hooks';
import BankTransactionsTable from './components/bankTransactionsTable';
import { BANK_TRANSACTIONS_CATEGORIES_MAP, DEFAULT_BANK_TRANSACTIONS_CATEGORY } from './constants';
import { BankTransactionCategoryId } from './types';

const BREADCRUMBS: Breadcrumb[] = [
  { label: 'Transactions', route: 'BankTransactions', param: DEFAULT_BANK_TRANSACTIONS_CATEGORY },
];

const BankTransactions = (): JSX.Element => {
  useTitle('Transactions');

  const { categoryId } = useParams<{ categoryId: BankTransactionCategoryId }>();
  const { bankTransactionsStore, bankTransactionsSortAndFilterStore } = useStores();

  return (
    <BankPerspectiveLayout
      breadcrumbs={BREADCRUMBS}
      categoriesMap={BANK_TRANSACTIONS_CATEGORIES_MAP}
      categoryId={categoryId}
      fetchItems={bankTransactionsStore.fetchItems}
      loading={bankTransactionsStore.loading}
      store={bankTransactionsStore}
      sortAndFilterStore={bankTransactionsSortAndFilterStore}
    >
      <BankTransactionsTable />
    </BankPerspectiveLayout>
  );
};

export default observer(BankTransactions);
