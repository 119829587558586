import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import DeleteContactModal from 'network/networkList/components';
import Loader from 'theme/atoms/loader';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import { useTitle } from 'utils/hooks';
import ContactProfileTopbar from '../components/ContactProfileTopbar';
import ContactActionSection from '../components/contactActionSection';
import ContactAvatar from '../components/contactAvatar/ContactAvatar';
import ContactInfo from '../components/contactInfo/ContactInfo';
import styles from './ContactProfile.module.scss';
import ContactProfilePanel from './ContactProfilePanel';

const ContactProfile = (): JSX.Element => {
  useTitle('Contact Details');

  const { networkManagingStore } = useStores();
  const { contactConnection, getContactConnection, fetchingContactConnection, deleteContactConnection } =
    networkManagingStore;
  const { contactId: contactConnectionId } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  useEffect(() => {
    if (contactConnectionId) {
      if (!contactConnection || contactConnection.id !== contactConnectionId) {
        getContactConnection(contactConnectionId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactConnectionId]);

  if (fetchingContactConnection) {
    return <Loader />;
  } else if (!contactConnection) {
    return <NoDataToDisplay copy="No user profile to display." height={100} />;
  }

  const notes = contactConnection.note && (
    <div>
      <div className={styles.notesTitle}>Notes:</div>
      <div className={styles.notesBody}>{contactConnection.note}</div>
    </div>
  );
  const onDeletionConfirm = (): void => {
    deleteContactConnection(contactConnection.id, undefined, () => navigate(routes.Network.path()));
  };

  return (
    <>
      <ContactProfileTopbar setIsModalOpen={setIsModalOpen} setIsPanelOpen={setIsPanelOpen} />
      <div className={styles.root}>
        <ContactAvatar id={contactConnection.id} />
        <div className={styles.container}>
          <div className={styles.header}>
            <ContactInfo
              name={contactConnection.contactOrganizationName}
              title={contactConnection.contactOrganizationTitle}
              withIcon
            />
            <ContactActionSection status={contactConnection.status} />
          </div>
          <div className={styles.body}>
            <div className={styles.descriptions}>
              <div className={styles.description}>{/* TODO fill when ready */}</div>
              {notes}
            </div>
          </div>
        </div>
      </div>

      <DeleteContactModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onDeletionConfirm={onDeletionConfirm}
        contactName={contactConnection.contactOrganizationName}
      />
      <ContactProfilePanel isOpen={isPanelOpen} onClose={() => setIsPanelOpen(false)} />
    </>
  );
};

export default observer(ContactProfile);
