import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { Grid, List } from 'artworks/artworksDisplaying';
import { Artwork } from 'artworks/types';
import Loader from 'theme/atoms/loader';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import SearchTable from 'theme/cells/searchTable/SearchTable';
import { SearchTableView } from 'theme/cells/searchTable/constants';
import { useTitle } from 'utils/hooks';
import ArtworksTopbar from '../artworksComponents/ArtworksTopbar';
import styles from './Artworks.module.scss';

const Artworks = (): JSX.Element => {
  useTitle('Artworks');

  const { artworksViewOptionsStore, artworksStore, artworksSortAndFilterStore } = useStores();
  const { layout, sizes, dataFields } = artworksViewOptionsStore;
  const { items, loading, selectedItemsIds, updateSelectedArtworksIds, searchQuery } = artworksStore;
  const searchedArtworks = items as Artwork[];

  const favouriteHandler = (id: string, favourite: boolean): void => {
    //TODO: add favourite handler
  };

  const ListElement = (): JSX.Element => (
    <List
      size={sizes[layout]}
      artworksToDisplay={searchedArtworks}
      updateSelectedArtworksIds={updateSelectedArtworksIds}
      selectedArtworksIds={selectedItemsIds}
      favouriteHandler={favouriteHandler}
      checkedFieldsIds={dataFields.list
        .filter((el) => el.isChecked)
        .map((el) => (el.child ? [el.id, el.child.id] : el.id))}
    />
  );

  const GridElement = (): JSX.Element => (
    <Grid
      size={sizes[layout]}
      artworksToDisplay={searchedArtworks}
      updateSelectedArtworksIds={updateSelectedArtworksIds}
      selectedArtworksIds={selectedItemsIds}
      favouriteHandler={favouriteHandler}
      checkedFieldsIds={dataFields.grid
        .filter((el) => el.isChecked)
        .map((el) => (el.child ? [el.id, el.child.id] : el.id))}
    />
  );

  const getLayoutElement = (): JSX.Element => {
    switch (layout) {
      case 'grid':
        return <GridElement />;
      default:
        return <ListElement />;
    }
  };

  return (
    <div>
      <ArtworksTopbar />
      <SearchTable view={SearchTableView.Artworks} store={artworksStore} filterStore={artworksSortAndFilterStore}>
        <h2 className={styles.header}>{searchQuery ? 'Search results' : 'All artworks'}</h2>
      </SearchTable>

      {searchedArtworks.length === 0 && loading && <Loader minHeight={150} />}
      {searchedArtworks.length === 0 && !loading && <NoDataToDisplay copy="No artworks to display." height={100} />}

      {searchedArtworks.length > 0 && <div className={styles.itemsWrapper}>{getLayoutElement()}</div>}
    </div>
  );
};
export default observer(Artworks);
