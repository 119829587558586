import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import Loader from 'theme/atoms/loader';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import SearchTable from 'theme/cells/searchTable';
import { SearchTableView } from 'theme/cells/searchTable/constants';
import { useTitle } from 'utils/hooks';
import styles from './Transactions.module.scss';
import ListTransactionsTable from './allTransactions/ListTransactionsTable';
import { TRANSACTION_CATEGORIES_WITH_STATUSES, TransactionCategoryId } from './types';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Transactions' }];

export const Transactions = (): JSX.Element => {
  useTitle('Transactions');

  const navigate = useNavigate();
  const { categoryId } = useParams<{ categoryId: TransactionCategoryId }>();
  const { transactionsStore, transactionsSortAndFilterStore } = useStores();
  const { items, loading } = transactionsStore;
  const [searchParams] = useSearchParams();

  const selectCategory = (id: string): void => {
    searchParams.delete('status');
    navigate({
      pathname: routes.Transactions.path(id),
      search: searchParams.toString(),
    });
  };

  const transactionsTable = loading ? (
    <Loader minHeight={150} />
  ) : items?.length > 0 ? (
    <ListTransactionsTable />
  ) : (
    <NoDataToDisplay copy="No transactions to display." height={100} />
  );

  return (
    <div className={styles.root}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>

      <SearchTable
        view={SearchTableView.Transactions}
        store={transactionsStore}
        filterStore={transactionsSortAndFilterStore}
      >
        <ul className={styles.categoriesWrapper}>
          {TRANSACTION_CATEGORIES_WITH_STATUSES.map(({ id, label }) => (
            <li key={id} onClick={() => selectCategory(id)} className={clsx({ [styles.active]: id === categoryId })}>
              {label}
            </li>
          ))}
        </ul>
      </SearchTable>

      {transactionsTable}
    </div>
  );
};

export default observer(Transactions);
