import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useIsAccessTokenFresh, useTitle } from 'utils/hooks';
import UserVerificationForm from '../userVerificationForm';
import styles from './Verification.module.scss';

const BREADCRUMBS: Breadcrumb[] = [
  { label: 'Settings', route: 'SettingsSecurity' },
  { label: 'Security', route: 'SettingsSecurity' },
  { label: 'Verification' },
];

const SettingsSecurityVerification = (): JSX.Element => {
  useTitle('Verification');

  const navigate = useNavigate();
  const isTokenFresh = useIsAccessTokenFresh();

  const location = useLocation();
  const redirectUrl = location.state?.redirectUrl;

  const { authStore } = useStores();
  const { userNeedsVerification, accessToken, verifyUser, setUserNeedsVerification } = authStore;

  useEffect(() => {
    if (!redirectUrl) navigate(routes.SettingsSecurity.path());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userNeedsVerification && isTokenFresh)
      navigate(redirectUrl, { state: { userNeedsVerification, ...location.state } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNeedsVerification, accessToken]);

  const handleSubmit = (trimmedEmail: string, password: string): void => {
    verifyUser(trimmedEmail, password).then((response) => {
      if (response?.success) setUserNeedsVerification(false);
    });
  };

  return (
    <div className={styles.root}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>

      <TitleBlack text="Verify your identity before proceeding" />
      <p>Enter your email and password to make changes.</p>

      <div className={styles.formContainer}>
        <UserVerificationForm onSubmit={handleSubmit} />

        {/* TODO uncomment when ready */}
        {/* <div className={styles.recoveryCodes}>
          <span>Having trouble?</span>
          <Button buttonType="textButton" text="Use recovery code" className={styles.recoveryCodesButton} />
        </div> */}
      </div>
    </div>
  );
};

export default observer(SettingsSecurityVerification);
