import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { DEFAULT_BANK_INVOICES_CATEGORY } from 'bankPerspective/bankInvoices/constants';
import { InvoiceState } from 'domain/types/Invoices.types';
import { TransactionStatus } from 'domain/types/Transactions.types';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { ButtonGroupType } from 'theme/atoms/buttonGroup';
import Loader from 'theme/atoms/loader';
import Modal from 'theme/atoms/modal';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import PDFViewer from 'theme/atoms/pdfViewer';
import TextArea from 'theme/atoms/textArea';
import BillingAndAddressesCard from 'theme/cells/billingAndAddressesCard';
import { useTitle } from 'utils/hooks';
import styles from './BankInvoice.module.scss';

const INVOICE_ACTION_RELATED_TRANSACTION_ALLOWED_STATUSES = [
  TransactionStatus.InvoiceVerification,
  TransactionStatus.WaitingForPayment,
  TransactionStatus.ReadyForExecution,
  TransactionStatus.Executing,
  TransactionStatus.Executed,
  TransactionStatus.SignificantPaymentsMade,
];

const INVOICE_ACTION_DISABLED_INFO = 'NOTE: Related transaction status does not permit actions on this invoice.';

interface StateButtonMapping {
  allowedStates: InvoiceState[];
  buttons: ButtonGroupType[];
}

interface ActionButtonsByView {
  [key: string]: StateButtonMapping;
}

const BREADCRUMBS: Breadcrumb[] = [
  { label: 'Invoices', route: 'BankInvoices', param: DEFAULT_BANK_INVOICES_CATEGORY },
  { label: 'Invoice details' },
];

const BankInvoice = (): JSX.Element | null => {
  useTitle('Invoice Details');

  const { invoiceId } = useParams();
  const { bankInvoiceStore, escrowAccountFormStore, bankTransactionStore } = useStores();
  const {
    acceptInvoice,
    bankNote,
    fetchBankInvoice,
    isInvoiceRejectionBankNoteModalOpen,
    loadingInvoiceDetails,
    markAsPaid,
    rejectInvoice,
    resetInvoiceRejectionModal,
    resetSelectedInvoice,
    selectedInvoice,
    setBankNote,
    setIsInvoiceRejectionBankNoteModalOpen,
    submittingInvoiceAcceptance,
    submittingInvoicePayment,
    submittingInvoiceRejection,
    submittingInvoiceRejectionWithoutNote,
  } = bankInvoiceStore;
  const { resetEscrowAccount } = escrowAccountFormStore;
  const { selectedTransaction, resetSelectedTransaction, loadingTransactionDetails } = bankTransactionStore;

  useEffect(() => {
    if (invoiceId) fetchBankInvoice(invoiceId);
  }, [invoiceId, fetchBankInvoice]);

  useEffect(() => {
    return () => {
      resetSelectedInvoice();
      resetEscrowAccount();
      resetSelectedTransaction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingInvoiceDetails || !selectedInvoice) return <Loader minHeight={150} />;

  const { assetInfo, id, state, externalId } = selectedInvoice;

  const isInvoiceActionAllowed =
    selectedTransaction && INVOICE_ACTION_RELATED_TRANSACTION_ALLOWED_STATUSES.includes(selectedTransaction.status);

  const actionButtonsByView: ActionButtonsByView = {
    verification: {
      allowedStates: [InvoiceState.PendingBankDecision],
      buttons: [
        {
          label: 'Accept invoice',
          buttonAction: () => acceptInvoice(id),
          loader: submittingInvoiceAcceptance,
          disabled: !isInvoiceActionAllowed || loadingTransactionDetails,
        },
        {
          label: 'Reject',
          buttonAction: () => setIsInvoiceRejectionBankNoteModalOpen(true),
          type: 'secondary' as const,
          disabled: !isInvoiceActionAllowed || loadingTransactionDetails,
        },
      ],
    },
    waitingPayment: {
      allowedStates: [InvoiceState.Accepted],
      buttons: [
        {
          label: 'Mark payment as released',
          buttonAction: () => markAsPaid(id),
          loader: submittingInvoicePayment,
          maxWidth: true,
          disabled: loadingTransactionDetails,
        },
      ],
    },
    rejected: {
      allowedStates: [InvoiceState.Rejected],
      buttons: [
        {
          label: 'Rejected',
          disabled: true,
        },
      ],
    },
  };

  function getButtonsForState(): ButtonGroupType[] {
    const defaultButtonGroup = actionButtonsByView['verification'].buttons;

    for (const view of Object.values(actionButtonsByView)) if (view.allowedStates.includes(state)) return view.buttons;

    return defaultButtonGroup;
  }

  const isRejectedInvoice = state === InvoiceState.Rejected;
  const isPaidInvoice = state === InvoiceState.Paid;

  const goToRelatedTransaction = routes.BankTransaction.path(externalId);

  const DOWNLOAD_INVOICE_FILE_NAME = `Invoice_id_${id}.pdf`;

  const invoiceDetails = (
    <>
      <BillingAndAddressesCard
        actionButtons={!isPaidInvoice ? getButtonsForState() : undefined}
        actionButtonsInfo={!isInvoiceActionAllowed ? INVOICE_ACTION_DISABLED_INFO : ''}
        isBankPerspective
        fromLabel="From"
        goToRelatedTransactionLink={goToRelatedTransaction}
        item={selectedInvoice}
        title="Invoice overview"
        toLabel="To"
      />
      <div className={styles.invoicePreview}>
        {assetInfo && assetInfo.presignedUrl ? (
          <PDFViewer
            isPDFRejected={isRejectedInvoice}
            downloadFileName={DOWNLOAD_INVOICE_FILE_NAME}
            url={assetInfo.presignedUrl}
          />
        ) : (
          <NoDataToDisplay copy="Invoice pdf file is not uploaded" height={100} />
        )}
      </div>
    </>
  );

  const handleBankNoteOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setBankNote(e.target.value);
  };

  return (
    <>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      {invoiceDetails}

      <Modal
        isOpen={isInvoiceRejectionBankNoteModalOpen}
        title="You've rejected the invoice"
        onClose={resetInvoiceRejectionModal}
        btnConfirm={{
          label: 'SEND COMMENT',
          function: () => rejectInvoice(id, true),
          loading: submittingInvoiceRejection,
        }}
        btnAbort={{ label: 'SKIP', function: () => rejectInvoice(id), loading: submittingInvoiceRejectionWithoutNote }}
        withoutUseOnClickOutside
      >
        <TextArea label="Add comment" name="bankNote" value={bankNote} onChange={handleBankNoteOnChange} />
      </Modal>
    </>
  );
};

export default observer(BankInvoice);
