import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { DEFAULT_BANK_TRANSACTIONS_CATEGORY } from 'bankPerspective/bankTransactions/constants';
import { TransactionStatus } from 'domain/types/Transactions.types';
import { ReactComponent as EditIcon } from 'theme/assets/svg/pencil.svg';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Button from 'theme/atoms/Button';
import Topbar from 'theme/atoms/Topbar';
import BillingCard from 'theme/atoms/billingCard';
import Loader from 'theme/atoms/loader';
import AddressInfoCard from 'theme/cells/addressInfoCard';
import BankAccountInfoCard from 'theme/cells/bankAccountInfoCard';
import { getTransactionData } from 'utils/helpers';
import { useTitle } from 'utils/hooks';
import styles from './BankTransaction.module.scss';
import BankTransactionInvoicesTable from './bankTransactionInvoicesTable';
import EscrowAccountForm from './escrowAccountForm';

const VIEW_BY_STATUS_GROUP = {
  noAccount: [TransactionStatus.Accepted],
  withAccountDetails: [
    TransactionStatus.AcceptedEscrowAccountGranted,
    TransactionStatus.Executed,
    TransactionStatus.Executing,
    TransactionStatus.InvoiceVerification,
    TransactionStatus.ReadyForExecution,
    TransactionStatus.SignificantPaymentsMade,
    TransactionStatus.WaitingForPayment,
    TransactionStatus.Closed,
  ],
  markedAsPaid: [
    TransactionStatus.Executed,
    TransactionStatus.Executing,
    TransactionStatus.InvoiceVerification,
    TransactionStatus.ReadyForExecution,
    TransactionStatus.SignificantPaymentsMade,
    TransactionStatus.Closed,
  ],
  withMarkAsPaidButton: [
    TransactionStatus.AcceptedEscrowAccountGranted,
    TransactionStatus.InvoiceVerification,
    TransactionStatus.WaitingForPayment,
  ],
};

function isStatusInGroup(status: TransactionStatus, key: keyof typeof VIEW_BY_STATUS_GROUP): boolean {
  const statusGroup = VIEW_BY_STATUS_GROUP[key];
  return statusGroup.includes(status);
}

export const BankTransaction = (): JSX.Element => {
  useTitle('Transaction Details');

  const { transactionId } = useParams();
  const { bankTransactionStore, escrowAccountFormStore } = useStores();
  const {
    fetchBankTransaction,
    loadingTransactionDetails,
    resetSelectedTransaction,
    selectedTransaction,
    sendTransactionToExecution,
    submittingTransactionToExecution,
  } = bankTransactionStore;
  const {
    escrowAccount,
    fetchEscrowAccountDetails,
    setEscrowAccountFormIsOpen,
    loadingEscrowAccountDetails,
    resetEscrowAccount,
  } = escrowAccountFormStore;

  useEffect(() => {
    if (transactionId) fetchBankTransaction(transactionId);
  }, [transactionId, fetchBankTransaction]);

  useEffect(() => {
    if (transactionId && selectedTransaction && !isStatusInGroup(selectedTransaction.status, 'noAccount'))
      fetchEscrowAccountDetails(transactionId);
  }, [transactionId, fetchEscrowAccountDetails, selectedTransaction]);

  useEffect(() => {
    return () => {
      resetSelectedTransaction();
      resetEscrowAccount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingTransactionDetails || !selectedTransaction) return <Loader minHeight={150} />;

  const { buyerOrPayerInfo, totalAmount, currency, status } = getTransactionData(selectedTransaction);

  const noAccountDetails = isStatusInGroup(status, 'noAccount');
  const withAccountDetails = isStatusInGroup(status, 'withAccountDetails');
  const isPaid = isStatusInGroup(status, 'markedAsPaid');
  const withMarkAsPaidButton = isStatusInGroup(status, 'withMarkAsPaidButton');

  const BREADCRUMBS: Breadcrumb[] = [
    { label: 'Transactions', route: 'BankTransactions', param: DEFAULT_BANK_TRANSACTIONS_CATEGORY },
    { label: `Transaction ${transactionId}` },
  ];

  const escrowDetailsLabel = 'Escrow account for transaction';

  const isEscrowEdit = status === TransactionStatus.AcceptedEscrowAccountGranted;

  const transactionDetails = (
    <>
      <BillingCard>
        <BillingCard.Header title="Transaction details" />
        <BillingCard.Body className={styles.transactionSitesAddresses}>
          {buyerOrPayerInfo && <AddressInfoCard label="Buyer" data={buyerOrPayerInfo} />}
          {noAccountDetails && (
            <BankAccountInfoCard data={escrowAccount} label={escrowDetailsLabel}>
              <Button text="Add Account" maxWidth onClick={() => setEscrowAccountFormIsOpen(true)} />
            </BankAccountInfoCard>
          )}
          {withAccountDetails && (
            <div className={styles.escrowDetailsWithEditButton}>
              <BankAccountInfoCard
                data={escrowAccount}
                label={escrowDetailsLabel}
                loading={loadingEscrowAccountDetails}
              />
              {isEscrowEdit && (
                <Button
                  className={styles.editButton}
                  buttonType="textButton"
                  iconStart={<EditIcon />}
                  text="Edit"
                  onClick={() => setEscrowAccountFormIsOpen(true)}
                  disabled={loadingEscrowAccountDetails}
                />
              )}
            </div>
          )}
        </BillingCard.Body>
        <BillingCard.Wrapper
          amount={totalAmount}
          childrenClassName={styles.billingWrapper}
          currency={currency}
          label="Amount due"
          sumupTitle="Total"
          withPaidIcon={isPaid}
        >
          {withMarkAsPaidButton && (
            <Button
              className={styles.markAsPaidButton}
              loading={submittingTransactionToExecution}
              text="Mark payment as released"
              onClick={() => sendTransactionToExecution(selectedTransaction.id)}
              maxWidth
            />
          )}
        </BillingCard.Wrapper>
      </BillingCard>
      <BankTransactionInvoicesTable invoices={selectedTransaction.invoices} />
    </>
  );

  return (
    <>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      {transactionDetails}

      <EscrowAccountForm />
    </>
  );
};

export default observer(BankTransaction);
